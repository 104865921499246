import { Icon, Layout } from 'antd';
import React, { Component } from 'react';
import logo from '../../assets/images/mg-logo.png';
import styles from './Main.module.scss';

const { Content, Footer } = Layout;

class Main extends Component {

  public render() {

    return (
      <Layout className={styles.container}>
        <Content>
          <div className={styles.logoContainer}>
            <img className={styles.logo} src={logo} />
            <span>i am <strong>mason</strong>.</span>
          </div>
        </Content>
        <Footer className={styles.footer}>
          <div className={styles.socialContainer}>
            <a href="https://github.com/mlg87" target="_blank">
              <Icon className={styles.icon} type="github" />
            </a>
            <a href="https://www.linkedin.com/in/masongoetz/" target="_blank">
              <Icon className={styles.icon} type="linkedin" />
            </a>
          </div>
        </Footer>
      </Layout>
    );

  }

}

export default Main;
